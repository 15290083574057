import React from "react";
import "./Video.css";
import main_background from '../../assets/images/main_background.png'
function Video() {
  return (
    <>
      {/* <iframe
        className="video-frame"
        width="500"
        height="270"
        src="https://www.youtube.com/embed/-2GzCy4STcw?si=6RIpaEgdqBLGpbeg?autoplay=1&mute=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe> */}
      <img
        width="100%"
        alt="Transition"
        src={main_background}
      />
    </>
  );
}

export default Video;