import React from "react";
import ClientTypeCard from "./ClientTypeCard";
import "./ClientTypeSection.css";

// Example icons, replace with actual paths to your icons
import iconCoaches from "../../../../assets/icons/coaches.svg";
import iconConsultants from "../../../../assets/icons/consultants.svg";
import iconCourseCreators from "../../../../assets/icons/course_creators.svg";
import iconServiceProviders from "../../../../assets/icons/service_provider.svg";
import iconInfopreneurs from "../../../../assets/icons/Infopreneurs.svg";
import iconDigitalProductCreators from "../../../../assets/icons/digital_product_creators.svg";
import iconDigitalMarketers from "../../../../assets/icons/digital_marketers.svg";
import iconIndustryExperts from "../../../../assets/icons/industry_expert.svg";
import iconAuthors from "../../../../assets/icons/authors.svg";
import iconSpeakers from "../../../../assets/icons/speakers.svg";

const clientTypes = [
  { icon: iconCoaches, title: "Coaches" },
  { icon: iconConsultants, title: "Consultants" },
  { icon: iconCourseCreators, title: "Course Creators" },
  { icon: iconServiceProviders, title: "Service Providers" },
  { icon: iconInfopreneurs, title: "Infopreneurs" },
  { icon: iconDigitalProductCreators, title: "Digital Product Creators" },
  { icon: iconDigitalMarketers, title: "Digital Marketers" },
  { icon: iconIndustryExperts, title: "Industry Experts" },
  { icon: iconAuthors, title: "Authors" },
  { icon: iconSpeakers, title: "Speakers" },
];

const ClientTypeSection = () => {
  return (
    <div className="client-type-section">
      <div className="client-type-grid">
        {clientTypes.map((clientType, index) => (
          <ClientTypeCard
            key={index}
            icon={clientType.icon}
            title={clientType.title}
          />
        ))}
      </div>
      <div className="background-image-container">
        <img
          className="background-image"
          alt="transition"
          src="https://www.conversionstudio.co/hosted/images/a4/18ebe95c594208af432167a46e4a97/hero-bg.svg"
        />
      </div>
    </div>
  );
};

export default ClientTypeSection;