import React from 'react';
import ReactPlayer from 'react-player';
import './LocalVideo.css';

const LocalVideo = () => {
  const fixedYoutubeUrl = 'https://youtu.be/pUt0o7dWOa0'; // Replace VIDEO_ID with the actual YouTube video ID

  return (
    <div className="local-video-container">
      <ReactPlayer
        className="video"
        url={fixedYoutubeUrl}
        controls={true}
        playing={true}
        muted={true}
        loop={true}
        width="100%"
        height="auto"
      />
    </div>
  );
};

export default LocalVideo;
