import React from "react";
import "./Top1.css";
import BookButton from "./BookButton";
import logo from '../../assets/images/logo.jpg'
import LocalVideo from "../videoSection/LocalVideo";
function Top1(props) {
  return (
    <>
      <div className="logo-div center">
        <img
          className="logo"
          src={logo}
          alt="LOGO"
        />
      </div>

      <div style={{marginTop: "0px"}} className="headline center">
        <b className="headline-simple-text">
          The Meta Ads Agency, <span className="headline-highlight-text"> That Triples Revenue </span>
        </b>
        <b className="headline-simple-text">Not Your Budget Within 90 Days.</b>
      </div>

      <div className="sub-headline center">
        Justkonvert's tailor-made marketing strategies are the proven that will
        help you soar above the rest.
        <LocalVideo></LocalVideo>
      </div>

      
      <BookButton></BookButton>
    </>
  );
}

export default Top1;