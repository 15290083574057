import React from "react";
import TeamCard from "./TeamCard";
import "./TeamSection.css";
import salman from '../../assets/images/Salman.jpg'
import media from '../../assets/images/Sami.jpg'
import website from '../../assets/images/website.jpg'
import graphic from '../../assets/images/graphic.jpg'
import strategy from '../../assets/images/Mariam.jpg'
import video from '../../assets/images/video.jpg'
import p_manager from '../../assets/images/Huma.jpg'
import f_designer from '../../assets/images/Ahsan.jpg'

const teamMembers = [
  {
    name: "Salman",
    role: "Founder",
    image:
      salman,
    gradientType: "black",
  },
  {
    name: "Mariam",
    role: "Crative Strategist",
    image: strategy,
    gradientType: "black",
  },
  {
    name: "Sami",
    role: "Sr. Media Buyer",
    image: media,
    gradientType: "black",
  },
  {
    name: "Huma",
    role: "Project Manager",
    image: p_manager,
    gradientType: "black",
  },
  {
    name: "Junaid",
    role: "Video Editor",
    image: video,
    gradientType: "black",
  },
  {
    name: "Ahsan",
    role: "Funnel Designer",
    image: f_designer,
    gradientType: "black",
  },
  {
    name: "Raiyaan",
    role: "Website Developer",
    image: website,
    gradientType: "black",
  },
  {
    name: "Anish",
    role: "Graphic Designer",
    image: graphic,
    gradientType: "black",
  }
];

const TeamSection = () => {
  return (
    <div className="team-section">
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <TeamCard
            key={index}
            name={member.name}
            role={member.role}
            image={member.image}
            gradientType={member.gradientType}
          />
        ))}
      </div>
    </div>
  );
};

export default TeamSection;