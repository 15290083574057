import React from "react";
import TestimonialCard from "./TestimonialCard";
import "./TestimonialSection.css";

const testimonials = [
  {
    videoUrl: "https://youtu.be/nCiwl6HxCNg", // YouTube video 1
    quote: "“Salman has been absolutely amazing. He managed my entire ad campaign, and I saw fascinating lead generation results.”",
    name: "Jaime Dongo",
    title: "Founder of Mr Mortgage loans",
  },
  
  {
    videoUrl: "https://youtube.com/shorts/sFnKBdjYF7A", // YouTube video 2
    quote: "“I am not usually the one to write reviews, but he knows what he is talking about, genuinely cares, and is very knowledgeable.“",
    name: "Lucie Gray",
    title: "Founder of theselfloveacademy",
  },
  {
    videoUrl: "https://youtube.com/shorts/CKTAulu-D6A", // YouTube video 3
    quote: "“He crushed it in every aspect of our projects. He finds out what will give you the best results rather than just finishing the job”",
    name: "Chase Wessendorf",
    title: "CEO of Macro Buildz",
  },
];

const TestimonialSection = () => {
  return (
    <>
      <div className="testimonial-section">
        {testimonials.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            videoUrl={testimonial.videoUrl}
            quote={testimonial.quote}
            name={testimonial.name}
            title={testimonial.title}
            lastVideo={false}
          />
        ))}
        <TestimonialCard
          videoUrl={"https://youtube.com/shorts/gbD3q_Y6h5U"} // YouTube video 4
          quote={
            "“He has helped us a lot by running campaigns and optimizing funnels. He's a really cool dude.”"
          }
          name={"Christopher"}
          title={"Founder of Christoph Olivier Consulting"}
          lastVideo={true}
        />
      </div>
    </>
  );
};

export default TestimonialSection;
