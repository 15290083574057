import React from "react";
import ReactPlayer from "react-player";
import "./TestimonialCard.css";


const TestimonialCard = ({ videoUrl, quote, name, title, lastVideo }) => {

  
  return (
    <div className="testimonial-card">
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={videoUrl}
          width="100%"
          height="100%"
          controls={true}
          muted={true}
          loop={true}
          playing={true}
        />
      </div>
      <p className="quote">{quote}</p>
      <p className="name" >— {name}</p>
      <p className="title">{title}</p>
    </div>
  );
};

export default TestimonialCard;
