import React from "react";
import CardSection from "./Card-Section/CardSection";

function Choose() {
  return (
    <>
      <div className="headline-div center">
        <span className="headline-h1" style={{paddingTop: "40px"}}>
          <b>Why Choose JustKonvert?</b>
        </span>
        <h1 style={{ marginTop: 0 }}>
          <u className="sub-headline center" style={{ color: "#eca24e", textDecoration: "none", fontWeight: "normal", padding: "0px 5px"}}>
           Loads of marketing agencies make lots of promises. At JustKonvert, <br/> we produce results
          </u>
        </h1>
      </div>
      <div className="center">
        <CardSection></CardSection>
      </div>
    </>
  );
}

export default Choose;
