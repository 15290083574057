import React from 'react';
import './TransitionSection.css'; // Create and import a CSS file for styling
import icon1 from '../../assets/icons/icon1.svg'
import icon2 from '../../assets/icons/icon2.svg'
import icon3 from '../../assets/icons/icon3.svg'
import icon4 from '../../assets/icons/icon4.svg'




const TransitionBenefits = () => {
  return (
    <>
      <div className="transition-benefits">
        <div className="benefit-item">
          <img src={icon1} alt="Build trust with your prospects" />
          <p>Build trust with your prospects</p>
        </div>
        <div className="benefit-item">
          <img src={icon2} alt="Stand out from competitors" />
          <p>Stand out from competitors</p>
        </div>
        <div className="benefit-item">
          <img src={icon3} alt="Appear as a true authority" />
          <p>Appear as a true authority</p>
        </div>
        <div className="benefit-item">
          <img src={icon4} alt="Increase your sales & conversions" />
          <p>Increase your sales & conversions</p>
        </div>
      </div>
    </>
  );
};

export default TransitionBenefits;